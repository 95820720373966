<template>
    <div>
      <v-container fluid>
        <base-material-card
          icon="mdi-clipboard-text"
          title="ผู้ใช้งานระบบ"
          class="px-5 py-3"
        >
          <v-card class="pa-2" outlined>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="text-right">               
              </v-col>
              <v-col cols="12" md="6" class="text-right">
                <v-btn
                  large
                  right
                  depressed
                  color="primary"
                  @click.native="userAdd()"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
            single-line
            hide-details
            dense
            filled
            class="mb-2"
          />
          <v-data-table
            color="success"
            :loading="loading"
            :headers="headers"
            :items="users"
            :search="search"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:item.user_status="{ item }">
              <v-chip v-if="item.user_status === 'B'" color="green" dark
                >สถานศึกษา</v-chip
              >
              <v-chip v-else-if="item.user_status === 'A'" color="info" dark
                >ผู้ดูแลระบบ</v-chip
              >
              <v-chip v-else-if="item.user_status === 'G'" color="pink" dark
                >สอจ.</v-chip
              >
              <v-chip v-else-if="item.user_status === 'C'" color="warning" dark
                >สถาบัน</v-chip
              >
              <v-chip v-else-if="item.user_status === 'S'" color="purple" dark
                >ศูนย์ส่งเสริมและพัฒนาอาชีวศึกษา</v-chip
              >
              <v-chip v-else>ไม่ได้กำหนด</v-chip>
            </template>
  
            <template v-slot:item.LoginAdmin="{ item }">
              <v-icon
                color="info"
                @click="userLogin(item.user_name, item.user_password)"
              >
                mdi-puzzle
              </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon color="yellow" @click.stop="userEdit(item.user_ID)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:item.action_s="{ item }">
              <v-icon color="red" @click.stop="userDelete(item.user_ID)">
                mdi-delete
              </v-icon>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
              >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
            >
          </v-data-table>
        </base-material-card>
  
        <!--adduserdialog  -->
        <v-layout row justify-center>
          <v-dialog v-model="adduserdialog" persistent max-width="50%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                icon="mdi-account-multiple"
                title="เพิ่มข้อมูลสมาชิก"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text>
                <v-form ref="adduserform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex md6>
                        <v-select
                          outlined
                          :items="userstatus"
                          item-text="user_status_name"
                          item-value="user_status_sub"
                          v-model="adduser.user_status"
                          label="User Type"
                          required
                          :rules="[v => !!v || '']"
                        ></v-select>
                      </v-flex>
                      <v-flex md6>
                        <v-text-field
                          outlined
                          label="Username"
                          v-model="adduser.user_name"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex md6>
                        <v-text-field
                          outlined
                          label="Password"
                          v-model="adduser.user_password"
                          type="password"
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex md6>
                        <v-text-field
                          outlined
                          label="Confirm Password"
                          v-model="adduser.user_confirmpassword"
                          type="password"
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-flex md6>
                        <v-text-field
                          outlined
                          label="Firstname"
                          v-model="adduser.user_firstname"
                          require
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex md6>
                        <v-text-field
                          outlined
                          label="Lastname"
                          v-model="adduser.user_lastname"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 v-if="adduser.user_status == 'B'">
                        <v-autocomplete
                          outlined
                          :items="colleges"
                          item-text="college_name"
                          item-value="college_ID"
                          v-model="adduser.college_ID"
                          label="College"
                          required
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>
                      <hr />
                    </v-layout>
                    <h3>สิทธิ์ในการใช้งานระบบ</h3>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="adduser.userauthorityAdmin"
                          value="1"
                          label="ผู้ดูแลระบบ"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="adduser.userauthorityMoveTeach"
                          value="1"
                          label="ย้ายสายการสอน"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="adduser.userauthorityMoveManage"
                          value="1"
                          label="ย้ายสายบริหาร"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="adduser.userauthorityManpower"
                          value="1"
                          label="อัตรากำลัง"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="adduser.userauthorityAssitantTeach"
                          value="1"
                          label="ประเมินครูผู้ช่วย"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  large
                  @click.stop="adduserdialog = false"
                  rounded
                  ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="success"
                  @click.stop="adduserSubmit()"
                  rounded
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model deleteuserdialog -->
        <v-layout>
          <v-dialog v-model="deleteuserdialog" persistent max-width="40%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-delete"
                title="ลบข้อมูลผู้ใช้"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form ref="deleteuserform" lazy-validation>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            ยืนยันการลบข้อมูลผู้ใช้ {{ edituser.user_name }}
                            <span v-if="edituser.college_name">{{
                              edituser.college_name
                            }}</span>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large @click.stop="deleteuserdialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="red darken-3"
                  @click.stop="deleteuserSubmit()"
                  dark
                >
                  <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model edituserdialog -->
        <v-layout row justify-center>
          <v-dialog v-model="edituserdialog" persistent max-width="80%">
            <v-card class="mx-auto pa-6">
              <base-material-card
                color="yellow"
                icon="mdi-clipboard-text"
                title="แก้ไขข้อมูลผู้ใช้งานระบบ"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text>
                <v-form ref="edituserform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                    
                      <v-flex md4>
                        <v-text-field
                          outlined
                          label="Username"
                          v-model="edituser.user_name"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4>
                        <v-text-field
                          outlined
                          label="Password"
                          v-model="edituser.user_password"
                          type="password"
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4>
                        <v-text-field
                          outlined
                          label="Confirm Password"
                          v-model="edituser.user_confirmpassword"
                          type="password"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-flex md6>
                        <v-text-field
                          outlined
                          label="Firstname"
                          v-model="edituser.user_firstname"
                          require
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex md6>
                        <v-text-field
                          outlined
                          label="Lastname"
                          v-model="edituser.user_lastname"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
              
  
                    
                    </v-layout>
                    <h3>สิทธิ์ในการใช้งานระบบ</h3>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="edituser.userauthorityAdmin"
                          value="1"
                          label="ผู้ดูแลระบบ"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="edituser.userauthorityMoveTeach"
                          value="1"
                          label="ย้ายสายการสอน"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="edituser.userauthorityMoveManage"
                          value="1"
                          label="ย้ายสายบริหาร"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="edituser.userauthorityManpower"
                          value="1"
                          label="อัตรากำลัง"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="edituser.userauthorityAssitantTeach"
                          value="1"
                          label="ประเมินครูผู้ช่วย"
                        ></v-checkbox>
                      </v-col>
  
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="edituser.userauthorityAssessSedirector"
                          value="1"
                          label="คัดเลือก รองผู้อำนวยการ"
                        ></v-checkbox>
                      </v-col>
  
  
                    </v-row>
                  </v-container>
                  <small>* จำเป็น</small>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  @click.stop="edituserdialog = false" rounded>
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn
                  
                  color="warning"
                  @click.stop="edituserSubmit()"
                  rounded
                >
                  <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          top
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <v-card-text>
            {{ snackbar.text }}
          </v-card-text>
  
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.show = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        loading: true,
        ApiKey: "HRvec2021",
        valid: true,
        adduserdialog: false,
        edituserdialog: false,
        deleteuserdialog: false,
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },
        users: [],
        adduser: {},
        edituser: {},
        search: "",
        pagination: {},
        headers: [
        
          { text: "ชื่อผู้ใช้งาน", align: "left", value: "user_name" },
          { text: "เบอร์โทร", align: "center", value: "collegeinfo_phone" },
          { text: "สถานะ", align: "left", value: "user_status" },
          { text: "ชื่อ/สถานศึกษา", align: "left", value: "user_firstname" },
          { text: "นามสกุล/บุคคล", align: "left", value: "user_lastname" },          
          { text: "สิทธิ์", align: "left", value: "authoritys" },
          { text: "Login", align: "left", value: "LoginAdmin" },
          {
            text: "แก้ไข",
            align: "center",
            value: "actions",
            icon: "mdi-file-document-edit"
          },
          {
            text: "ลบ",
            align: "center",
            value: "action_s",
            icon: "mdi-delete-forever"
          }
        ],
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],
  
        college: {},
        provinces: [],
        prefectures: [],
        userstatus: [],
        regions: [],
        region_ena: true,
        data_syslog: {},
        userauthorityAdmin: false,
        userauthorityMoveTeach: false,
        userauthorityMoveManage: false,
        userauthorityManpower: false,
        userauthorityAssitantTeach: false,
        userauthorityAdmin: 0,
        userauthorityMoveTeach: 0,
        userauthorityMoveManage: 0,
        userauthorityManpower: 0,
        userauthorityAssitantTeach: 0,
        group_userstatus:[],
        userstatus_select:"",
      };
    },
    async mounted() {     
      await this.userQueryAll();  
    },
    methods: {
    
      async userLogin(user_name, user_password) {
        let result = await this.$http.post("login.php?crud=admin", {
          user_name: user_name,
          user_password: user_password
        });
        if (result.data.user_status) {
          let user = result.data;
          user.system_lock = false;
          sessionStorage.setItem("user", JSON.stringify(user));
          if (user.user_status == "A") {
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/admin");
          }
          //administrator IPA2
          else if (user.user_status == "B") {
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/college");
          } //college
          else if (user.user_status == "C") this.$router.push("/iven");
          else if (user.user_status == "S") this.$router.push("/supervisions");
          //Vocational Education Institution
          else if (user.user_status == "D") this.$router.push("/personnel");
          //College personnel
          else if (user.user_status == "G") {
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/vecprovince");
          }
          //Vocation College Province
          else {
            sessionStorage.clear();
            this.dialog = true;
            this.dialog_msg = "ชื่อผู้ใช้ หรือรหัสผ่าน ไม่ถูกต้อง";
          }
        }
      },
      async userQueryAll() {
        this.loading = true;
        let result = await this.$http
          .post("user.php", {
            ApiKey: this.ApiKey,        
            user_status:"A"
          })
          .finally(() => (this.loading = false));
        this.users = result.data;
      },
      async userAdd() {
        this.adduser = {};
        this.adduserdialog = true;
      },
      async adduserSubmit() {
        if (this.$refs.adduserform.validate()) {
          this.adduser.ApiKey = this.ApiKey;
          let result = await this.$http.post("user.insert.php", this.adduser);
  
          if (result.data.status == true) {
            this.user = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.userQueryAll();
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "insert";
            this.data_syslog.page_log = "user";
            this.data_syslog.table_log = "user";
            this.data_syslog.detail_log =
              this.adduser.user_name + " : " + this.adduser.user_status;
            this.data_syslog.date_times = this.date_today_log;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
            this.userQueryAll();
          }
          this.adduserdialog = false;
        }
      },
      async userEdit(user_ID) {
        let result = await this.$http.post("user.php", {
          ApiKey: this.ApiKey,
          user_ID: user_ID
        });
        this.edituser = result.data;  
        this.edituser.user_password = "";
        this.edituserdialog = true;        
      },
      async edituserSubmit() {
        if (this.$refs.edituserform.validate()) {
          this.edituser.ApiKey = this.ApiKey;
          if (this.edituser.user_password == "")
            delete this.edituser.user_password;
          if(this.edituser.userauthorityAdmin!='1'){
            this.edituser.userauthorityAdmin=0
          }
          if(this.edituser.userauthorityMoveTeach!='1'){
            this.edituser.userauthorityMoveTeach=0
          }
          if(this.edituser.userauthorityMoveManage!='1'){
            this.edituser.userauthorityMoveManage=0
          }
          if(this.edituser.userauthorityManpower!='1'){
            this.edituser.userauthorityManpower=0
          }
          if(this.edituser.userauthorityAssitantTeach!='1'){
            this.edituser.userauthorityAssitantTeach=0
          }
          if(this.edituser.userauthorityAssessSedirector!='1'){
            this.edituser.userauthorityAssessSedirector=0
          }
          let result = await this.$http.post("user.update.php", this.edituser);
          
          
          if (result.data.status == true) {
            this.user = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.userQueryAll();
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "update";
            this.data_syslog.page_log = "user";
            this.data_syslog.table_log = "user";
            this.data_syslog.detail_log =
              this.edituser.user_name + " : " + this.edituser.user_status;
            this.data_syslog.date_times = this.date_today_log;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.edituserdialog = false;
        }
      },
      async userDelete(user_ID) {
        let result = await this.$http.post("user.php", {
          ApiKey: this.ApiKey,
          user_ID: user_ID
        });
        this.edituser = result.data;
        this.deleteuserdialog = true;
      },
      async deleteuserSubmit() {
        if (this.$refs.deleteuserform.validate()) {
          this.edituser.ApiKey = this.ApiKey;
          if (this.edituser.user_status == "D")
            await this.$http.post("committee.delete.php", {
              ApiKey: this.ApiKey,
              user_ID: this.edituser.user_ID
            });
          let result = await this.$http.post("user.delete.php", this.edituser);
          if (result.data.status == true) {
            this.user = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.userQueryAll();
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "delete";
            this.data_syslog.page_log = "user";
            this.data_syslog.table_log = "user";
            this.data_syslog.detail_log =
              this.edituser.user_name + " : " + this.edituser.user_status;
            this.data_syslog.date_times = this.date_today_log;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการลบผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.deleteuserdialog = false;
        }
      }
    },
    computed: {
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
      date_today_log() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
        let time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        today = dd + "/" + mm + "/" + yyyy + "/" + time;
        return today;
      }
    }
  };
  </script>
  